import {
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_REQUEST,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_FAILURE,
  USER_PROFILE_GET_SUCCESS,
  USER_PROFILE_GET_REQUEST,
  USER_PROFILE_GET_FAILURE,
} from "../Constants/AuthConstants";

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true, success: false };

    case USER_REGISTER_SUCCESS:
      return {
        loading: false,
        success: true,
        registerData: action.payload,
        message: action.payload.message,
      };

    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };

    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        userData: action.payload,
        message: action.payload.message,
      };

    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const UserProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PROFILE_UPDATE_REQUEST:
      return { loading: true, success: false };

    case USER_PROFILE_UPDATE_SUCCESS:
      return {
        loading: false,
        userDataProfile: action.payload,
        message: action.payload.message,
        success: true
      };

    case USER_PROFILE_UPDATE_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};

export const UserProfileGetReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PROFILE_GET_REQUEST:
      return { loading: true };

    case USER_PROFILE_GET_SUCCESS:
      return {
        loading: false,
        prfileData: action.payload,
        message: action.payload.message,
      };

    case USER_PROFILE_GET_FAILURE:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};