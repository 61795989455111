import {
  GET_CRAWLER_LIST_SUCCESS,
  GET_CRAWLER_LIST_REQUEST,
  GET_CRAWLER_LIST_FAILURE,
  POST_CRAWLER_LIST_SUCCESS,
  POST_CRAWLER_LIST_REQUEST,
  POST_CRAWLER_LIST_FAILURE,
  CRAWLER_GET_SUCCESS,
  CRAWLER_GET_REQUEST,
  CRAWLER_GET_FAILURE,
  SCHEDULE_SEND_SUCCESS,
  SCHEDULE_SEND_FAILURE,
  SCHEDULE_SEND_REQUEST,
  SCHEDULE_LIST_FAILURE,
  SCHEDULE_LIST_REQUEST,
  SCHEDULE_LIST_SUCCESS,
  GET_START_DATA_SUCCESS,
  GET_START_DATA_REQUEST,
  GET_START_DATA_FAILURE,
  START_DATA_All_FAILURE,
  START_DATA_All_REQUEST,
  START_DATA_All_SUCCESS,
  START_DATA_SINGLE_SUCCESS,
  START_DATA_SINGLE_REQUEST,
  START_DATA_SINGLE_FAILURE,
  TEAM_MEMBER_LIST_SUCCESS,
  TEAM_MEMBER_LIST_REQUEST,
  TEAM_MEMBER_LIST_FAILURE,
  ADD_TEAM_MEMBER_SUCCESS,
  ADD_TEAM_MEMBER_REQUEST,
  ADD_TEAM_MEMBER_FAILURE,
  TEAM_MEMBER_GET_BY_ID_SUCCESS,
  TEAM_MEMBER_GET_BY_ID_FAILURE,
  TEAM_MEMBER_GET_BY_ID_REQUEST,
  TEAM_MEMBER_DELETE_SUCCESS,
  TEAM_MEMBER_DELETE_FAILURE,
  TEAM_MEMBER_DELETE_REQUEST,
  GET_CITY_SUCCESS,
  GET_CITY_FAILURE,
  GET_CITY_REQUEST,
  KEYWORD_LIST_GET_SUCCESS,
  KEYWORD_LIST_GET_REQUEST,
  KEYWORD_LIST_GET_FAILURE,
  KEYWORD_ADD_REQUEST,
  KEYWORD_ADD_SUCCESS,
  KEYWORD_ADD_FAILURE,
  KEYWORD_GET_BY_ID_FAILURE,
  KEYWORD_GET_BY_ID_SUCCESS,
  KEYWORD_GET_BY_ID_REQUEST,
  KEYWORD_DELETE_SUCCESS,
  KEYWORD_DELETE_REQUEST,
  KEYWORD_DELETE_FAILURE,
  KEYWORD_UPDATE_SUCCESS,
  KEYWORD_UPDATE_REQUEST,
  KEYWORD_UPDATE_FAILURE,
  DELETE_CRAWLER_SUCCESS,
  DELETE_CRAWLER_FAILURE,
  DELETE_CRAWLER_REQUEST,
  STOP_CRAWLER_SUCCESS,
  STOP_CRAWLER_REQUEST,
  STOP_CRAWLER_FAILURE,
} from "../Constants/AdminConstants";

export const CrawlerListReducer = (state = { CrawlerList: [] }, action) => {
  switch (action.type) {
    case GET_CRAWLER_LIST_REQUEST:
      return { loading: true, success: false };

    case GET_CRAWLER_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        CrawlerList: action.payload,
        message: action.payload.message,
      };

    case GET_CRAWLER_LIST_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};

export const CrawlerPostReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_CRAWLER_LIST_REQUEST:
      return { loading: true, success: false };

    case POST_CRAWLER_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        Crawlerpost: action.payload,
        message: action.payload.message,
      };

    case POST_CRAWLER_LIST_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};


export const CrawlerGetReducer = (state = { CrawlerData: [] }, action) => {
  switch (action.type) {
    case CRAWLER_GET_REQUEST:
      return { loading: true, success: false };

    case CRAWLER_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        CrawlerData: action.payload,
        message: action.payload.message,
      };

    case CRAWLER_GET_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};


export const ScheduleSendReducer = (state = {}, action) => {
  switch (action.type) {
    case SCHEDULE_SEND_REQUEST:
      return { loading: true, success: false };

    case SCHEDULE_SEND_SUCCESS:
      return {
        loading: false,
        success: true,
        scheduleSend: action.payload,
        message: action.payload.message,
      };

    case SCHEDULE_SEND_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};

export const ScheduleListReducer = (state = { scheduleList: [] }, action) => {
  switch (action.type) {
    case SCHEDULE_LIST_REQUEST:
      return { loading: true, success: false };

    case SCHEDULE_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        scheduleList: action.payload,
        message: action.payload.message,
      };

    case SCHEDULE_LIST_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};

export const StartDataGetReducer = (state = { stateDataList: [] }, action) => {
  switch (action.type) {
    case GET_START_DATA_REQUEST:
      return { loading: true, success: false };

    case GET_START_DATA_SUCCESS:
      return {
        loading: false,
        success: true,
        stateDataList: action.payload,
        message: action.payload.message,
      };

    case GET_START_DATA_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};


export const StartDataAllReducer = (state = {}, action) => {
  switch (action.type) {
    case START_DATA_All_REQUEST:
      return { loading: true, success: false };

    case START_DATA_All_SUCCESS:
      return {
        loading: false,
        success: true,
        stateDataAll: action.payload,
        message: action.payload.message,
      };

    case START_DATA_All_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};

export const StartDataSingleReducer = (state = {}, action) => {
  switch (action.type) {
    case START_DATA_SINGLE_REQUEST:
      return { loading: true, success: false };

    case START_DATA_SINGLE_SUCCESS:
      return {
        loading: false,
        success: true,
        stateDataSingle: action.payload,
        message: action.payload.message,
      };

    case START_DATA_SINGLE_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};

export const TeamMemberReducer = (state = { teamMemberList: [] }, action) => {
  switch (action.type) {
    case TEAM_MEMBER_LIST_REQUEST:
      return { loading: true, success: false };

    case TEAM_MEMBER_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        teamMemberList: action.payload,
        message: action.payload.message,
      };

    case TEAM_MEMBER_LIST_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};

export const TeamMemberAddReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_TEAM_MEMBER_REQUEST:
      return { loading: true, success: false };

    case ADD_TEAM_MEMBER_SUCCESS:
      return {
        loading: false,
        success: true,
        addTeamMember: action.payload,
        message: action.payload.message,
      };

    case ADD_TEAM_MEMBER_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};

export const TeamMemberSingleReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_MEMBER_GET_BY_ID_REQUEST:
      return { loading: true, success: false };

    case TEAM_MEMBER_GET_BY_ID_SUCCESS:
      return {
        loading: false,
        success: true,
        SingleTeamMember: action.payload,
        message: action.payload.message,
      };

    case TEAM_MEMBER_GET_BY_ID_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};

export const DeleteTeamMemberReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_MEMBER_DELETE_REQUEST:
      return { loading: true, success: false };

    case TEAM_MEMBER_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        DeleteTeamMember: action.payload,
        message: action.payload.message,
      };

    case TEAM_MEMBER_DELETE_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};

export const GetCityReducer = (state = { CityList: [] }, action) => {
  switch (action.type) {
    case GET_CITY_REQUEST:
      return { loading: true, success: false };

    case GET_CITY_SUCCESS:
      return {
        loading: false,
        success: true,
        CityList: action.payload,
        message: action.payload.message,
      };

    case GET_CITY_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};
 


export const KeywordListReducer = (state = { KeywordList: [] }, action) => {
  switch (action.type) {
    case KEYWORD_LIST_GET_REQUEST:
      return { loading: true, success: false };

    case KEYWORD_LIST_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        KeywordList: action.payload,
        message: action.payload.message,
      };

    case KEYWORD_LIST_GET_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};


export const KeywordAddReducer = (state = {}, action) => {
  switch (action.type) {
    case KEYWORD_ADD_REQUEST:
      return { loading: true, success: false };

    case KEYWORD_ADD_SUCCESS:
      return {
        loading: false,
        success: true,
        addKeyword: action.payload,
        message: action.payload.message,
      };

    case KEYWORD_ADD_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};

export const KeywordGetByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case KEYWORD_GET_BY_ID_REQUEST:
      return { loading: true, success: false };

    case KEYWORD_GET_BY_ID_SUCCESS:
      return {
        loading: false,
        success: true,
        getByIdKeyword: action.payload,
        message: action.payload.message,
      };

    case KEYWORD_GET_BY_ID_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};


export const KeywordDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case KEYWORD_DELETE_REQUEST:
      return { loading: true, success: false };

    case KEYWORD_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        deleteKeyword: action.payload,
        message: action.payload.message,
      };

    case KEYWORD_DELETE_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};


export const KeywordUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case KEYWORD_UPDATE_REQUEST:
      return { loading: true, success: false };

    case KEYWORD_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        updateKeyword: action.payload,
        message: action.payload.message,
      };

    case KEYWORD_UPDATE_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};

export const DeleteCrawlerReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CRAWLER_REQUEST:
      return { loading: true, success: false };

    case DELETE_CRAWLER_SUCCESS:
      return {
        loading: false,
        success: true,
        deleteCrawler: action.payload,
        message: action.payload.message,
      };

    case DELETE_CRAWLER_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};

export const CrawlerStopReducer = (state = {}, action) => {
  switch (action.type) {
    case STOP_CRAWLER_REQUEST:
      return { loading: true, success: false };

    case STOP_CRAWLER_SUCCESS:
      return {
        loading: false,
        success: true,
        CrawlerStop: action.payload,
        message: action.payload.message,
      };

    case STOP_CRAWLER_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};
