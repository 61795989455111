import { createStore, applyMiddleware, combineReducers } from "redux";
import {thunk} from "redux-thunk";
import {
  userLoginReducer,
  userRegisterReducer,
  UserProfileReducer,
  UserProfileGetReducer,
} from "./Redux/Reducers/AuthReducer";
import {
  CrawlerListReducer,
  CrawlerPostReducer,
  CrawlerGetReducer,
  ScheduleSendReducer,
  ScheduleListReducer,
  StartDataGetReducer,
  StartDataAllReducer,
  StartDataSingleReducer,
  TeamMemberReducer,
  TeamMemberAddReducer,
  TeamMemberSingleReducer,
  DeleteTeamMemberReducer,
  GetCityReducer,
  KeywordListReducer,
  KeywordAddReducer,
  KeywordDeleteReducer,
  KeywordGetByIdReducer,
  KeywordUpdateReducer,
  DeleteCrawlerReducer,
  CrawlerStopReducer,
} from "./Redux/Reducers/AdminReducer";

const reducer = combineReducers({
  authReducer: userLoginReducer,
  userRegisterReducer,
  CrawlerListReducer,
  CrawlerPostReducer,
  CrawlerGetReducer,
  ScheduleSendReducer,
  ScheduleListReducer,
  UserProfileReducer,
  UserProfileGetReducer,
  StartDataGetReducer,
  StartDataAllReducer,
  StartDataSingleReducer,
  TeamMemberReducer,
  TeamMemberAddReducer,
  TeamMemberSingleReducer,
  DeleteTeamMemberReducer,
  GetCityReducer,
  KeywordListReducer,
  KeywordAddReducer,
  KeywordDeleteReducer,
  KeywordGetByIdReducer,
  KeywordUpdateReducer,
  DeleteCrawlerReducer,
  CrawlerStopReducer,
});
const userDataFromStorage = localStorage.getItem("userData")
  ? JSON.parse(localStorage.getItem("userData"))
  : null;

  const initialState = {
    authReducer: { userData: userDataFromStorage },
  };

  const middleware = [thunk];

  const store = createStore(
    reducer,
    initialState,
    applyMiddleware(...middleware)
  );
  
  export default store;
