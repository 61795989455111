import React, { Suspense, lazy, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import LoadingSpinner from "./Components/LoadingSpinner";
import ProtectedRoute from "./Routing/ProtectedRoute";
const LazyLogin = lazy(() => delayForDemo(import('./Components/Auth/Login')));
const LazySignup = lazy(() => delayForDemo(import('./Components/Auth/Signup')));
const LazyAdminLayout = lazy(() => delayForDemo(import('./Layout/AdminLayout/AdminLayout')));
const LazyDashboard = lazy(() => delayForDemo(import('./Components/AdminComponents/Dashboard')));
const LazyThankYouPage = lazy(() => delayForDemo(import('./Components/Auth/ThankYouPage')));
const LazyVerifyEmail = lazy(() => delayForDemo(import('./Components/Auth/VerifyEmail')));
const LazyResetPassword = lazy(() => delayForDemo(import('./Components/Auth/ResetPassword')));
const LazyCrawlerHistory = lazy(() => delayForDemo(import('./Components/AdminComponents/CrawlerHistory')));
const LazyScheduleList = lazy(() => delayForDemo(import('./Components/AdminComponents/ScheduleList')));
const LazyScheduleAdd = lazy(() => delayForDemo(import('./Components/AdminComponents/ScheduleAdd')));
const LazyTeamMembers = lazy(() => delayForDemo(import('./Components/AdminComponents/TeamMembers')));
const LazyCrawler = lazy(() => delayForDemo(import('./Components/AdminComponents/Crawler')));
const LazyAddNewMembers = lazy(() => delayForDemo(import('./Components/AdminComponents/AddNewMembers')));
const LazyAdminProfile = lazy(() => delayForDemo(import('./Components/AdminComponents/AdminProfile')));
const LazyKeyword = lazy(() => delayForDemo(import('./Components/AdminComponents/Keyword')));
const LazyKeywordAdd = lazy(() => delayForDemo(import('./Components/AdminComponents/KeywordAdd')));
const LazyDonwloadCsv = lazy(() => delayForDemo(import('./Components/AdminComponents/DonwloadCsv')));

function App() {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);
  
  return (
    <div className="App">
      {loading && <>
        <LoadingSpinner />
      </>}
      <Router>
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            {/* Auth Routes */}
            <Route path='/login' element={<LazyLogin />} />
            <Route path='/signup' element={<LazySignup />} />
            <Route path='/thank-you' element={<LazyThankYouPage />} />
            <Route path='/verify-email/:token/:uid/' element={<LazyVerifyEmail />} />
            <Route path='/download-csv/:id/' element={<LazyDonwloadCsv />} />
            <Route path='/reset-password/:ResetpasswordId/:userId/' element={<LazyResetPassword />} />
            {/* Admin Routes */}

            <Route element={<LazyAdminLayout />}>
              <Route path='/dashboard' element={
                <ProtectedRoute>
                  <LazyDashboard />
                </ProtectedRoute>
              } />

              <Route path='/history' element={
                <ProtectedRoute>
                  <LazyCrawlerHistory />
                </ProtectedRoute>
              } />

              <Route path='/schedule' element={
                <ProtectedRoute>
                  <LazyScheduleList />
                </ProtectedRoute>
              } />

              <Route path='/add-schedule' element={
                <ProtectedRoute>
                  <LazyScheduleAdd />
                </ProtectedRoute>
              } />

              <Route path='/team-members' element={
                <ProtectedRoute>
                  <LazyTeamMembers />
                </ProtectedRoute>
              } />

              <Route path='/crawler' element={
                <ProtectedRoute>
                  <LazyCrawler />
                </ProtectedRoute>
              } />

              <Route path='/add-member' element={
                <ProtectedRoute>
                  <LazyAddNewMembers />
                </ProtectedRoute>
              } />


              <Route path='/edit-member/:id' element={
                <ProtectedRoute>
                  <LazyAddNewMembers />
                </ProtectedRoute>
              } />

              <Route path='/profile' element={
                <ProtectedRoute>
                  <LazyAdminProfile />
                </ProtectedRoute>
              } />


              <Route path='/keywords' element={
                <ProtectedRoute>
                  <LazyKeyword />
                </ProtectedRoute>
              } />

               <Route path='/add-keyword' element={
                <ProtectedRoute>
                  <LazyKeywordAdd />
                </ProtectedRoute>
              } />

            </Route>
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;

function delayForDemo(promise) {
  return new Promise(resolve => {
    setTimeout(resolve, 1000);
  }).then(() => promise);
}
