export const GET_CRAWLER_LIST_SUCCESS = 'GET_CRAWLER_LIST_SUCCESS'
export const GET_CRAWLER_LIST_REQUEST = 'GET_CRAWLER_LIST_REQUEST'
export const GET_CRAWLER_LIST_FAILURE = 'GET_CRAWLER_LIST_FAILURE'

export const POST_CRAWLER_LIST_SUCCESS = 'POST_CRAWLER_LIST_SUCCESS'
export const POST_CRAWLER_LIST_REQUEST = 'POST_CRAWLER_LIST_REQUEST'
export const POST_CRAWLER_LIST_FAILURE = 'POST_CRAWLER_LIST_FAILURE'

export const CRAWLER_GET_SUCCESS = 'CRAWLER_GET_SUCCESS'
export const CRAWLER_GET_REQUEST = 'CRAWLER_GET_REQUEST'
export const CRAWLER_GET_FAILURE = 'CRAWLER_GET_FAILURE'

export const SCHEDULE_SEND_SUCCESS = 'SCHEDULE_SEND_SUCCESS'
export const SCHEDULE_SEND_FAILURE = 'SCHEDULE_SEND_FAILURE'
export const SCHEDULE_SEND_REQUEST = 'SCHEDULE_SEND_REQUEST'

export const SCHEDULE_LIST_FAILURE = 'SCHEDULE_LIST_FAILURE'
export const SCHEDULE_LIST_REQUEST = 'SCHEDULE_LIST_REQUEST'
export const SCHEDULE_LIST_SUCCESS = 'SCHEDULE_LIST_SUCCESS'

export const GET_START_DATA_SUCCESS = 'GET_START_DATA_SUCCESS'
export const GET_START_DATA_REQUEST = 'GET_START_DATA_REQUEST'
export const GET_START_DATA_FAILURE = 'GET_START_DATA_FAILURE'

export const START_DATA_All_FAILURE = 'START_DATA_All_FAILURE'
export const START_DATA_All_REQUEST = 'START_DATA_All_REQUEST'
export const START_DATA_All_SUCCESS = 'START_DATA_All_SUCCESS'

export const START_DATA_SINGLE_SUCCESS = 'START_DATA_SINGLE_SUCCESS'
export const START_DATA_SINGLE_REQUEST = 'START_DATA_SINGLE_REQUEST'
export const START_DATA_SINGLE_FAILURE = 'START_DATA_SINGLE_FAILURE'

export const TEAM_MEMBER_LIST_SUCCESS = "TEAM_MEMBER_LIST_SUCCESS"
export const TEAM_MEMBER_LIST_REQUEST = "TEAM_MEMBER_LIST_REQUEST"
export const TEAM_MEMBER_LIST_FAILURE = "TEAM_MEMBER_LIST_FAILURE"

export const ADD_TEAM_MEMBER_SUCCESS = "ADD_TEAM_MEMBER_SUCCESS"
export const ADD_TEAM_MEMBER_REQUEST = "ADD_TEAM_MEMBER_REQUEST"
export const ADD_TEAM_MEMBER_FAILURE = "ADD_TEAM_MEMBER_FAILURE"

export const TEAM_MEMBER_GET_BY_ID_SUCCESS = "TEAM_MEMBER_GET_BY_ID_SUCCESS"
export const TEAM_MEMBER_GET_BY_ID_FAILURE = "TEAM_MEMBER_GET_BY_ID_FAILURE"
export const TEAM_MEMBER_GET_BY_ID_REQUEST = "TEAM_MEMBER_GET_BY_ID_REQUEST"

export const TEAM_MEMBER_DELETE_SUCCESS = "TEAM_MEMBER_DELETE_SUCCESS"
export const TEAM_MEMBER_DELETE_FAILURE = "TEAM_MEMBER_DELETE_FAILURE"
export const TEAM_MEMBER_DELETE_REQUEST = "TEAM_MEMBER_DELETE_REQUEST"

export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS'
export const GET_CITY_FAILURE = 'GET_CITY_FAILURE'
export const GET_CITY_REQUEST = 'GET_CITY_REQUEST'

export const KEYWORD_LIST_GET_SUCCESS = 'KEYWORD_LIST_GET_SUCCESS'
export const KEYWORD_LIST_GET_REQUEST = 'KEYWORD_LIST_GET_REQUEST'
export const KEYWORD_LIST_GET_FAILURE = 'KEYWORD_LIST_GET_FAILURE'

export const KEYWORD_ADD_REQUEST = 'KEYWORD_ADD_REQUEST'
export const KEYWORD_ADD_SUCCESS = 'KEYWORD_ADD_SUCCESS'
export const KEYWORD_ADD_FAILURE = 'KEYWORD_ADD_FAILURE'

export const KEYWORD_GET_BY_ID_FAILURE = 'KEYWORD_GET_BY_ID_FAILURE'
export const KEYWORD_GET_BY_ID_SUCCESS = 'KEYWORD_GET_BY_ID_SUCCESS'
export const KEYWORD_GET_BY_ID_REQUEST = 'KEYWORD_GET_BY_ID_REQUEST'

export const KEYWORD_DELETE_SUCCESS = 'KEYWORD_DELETE_SUCCESS'
export const KEYWORD_DELETE_REQUEST = 'KEYWORD_DELETE_REQUEST'
export const KEYWORD_DELETE_FAILURE = 'KEYWORD_DELETE_FAILURE'


export const KEYWORD_UPDATE_SUCCESS = 'KEYWORD_UPDATE_SUCCESS'
export const KEYWORD_UPDATE_REQUEST = 'KEYWORD_UPDATE_REQUEST'
export const KEYWORD_UPDATE_FAILURE = 'KEYWORD_UPDATE_FAILURE'

export const DELETE_CRAWLER_SUCCESS = 'DELETE_CRAWLER_SUCCESS'
export const DELETE_CRAWLER_FAILURE = 'DELETE_CRAWLER_FAILURE'
export const DELETE_CRAWLER_REQUEST = 'DELETE_CRAWLER_REQUEST'

export const STOP_CRAWLER_SUCCESS = "STOP_CRAWLER_SUCCESS"
export const STOP_CRAWLER_REQUEST = "STOP_CRAWLER_REQUEST"
export const STOP_CRAWLER_FAILURE = "STOP_CRAWLER_FAILURE"


