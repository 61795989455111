export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_LOGOUT = "USER_LOGOUT";

export const USER_PROFILE_UPDATE_SUCCESS = "USER_PROFILE_UPDATE_SUCCESS"
export const USER_PROFILE_UPDATE_REQUEST = "USER_PROFILE_UPDATE_REQUEST"
export const USER_PROFILE_UPDATE_FAILURE = "USER_PROFILE_UPDATE_FAILURE"

export const USER_PROFILE_GET_SUCCESS = 'USER_PROFILE_GET_SUCCESS'
export const USER_PROFILE_GET_REQUEST = 'USER_PROFILE_GET_REQUEST'
export const USER_PROFILE_GET_FAILURE = 'USER_PROFILE_GET_FAILURE'
