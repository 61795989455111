import React from "react";
import "./Spinner.css";

const index = () => {
  return (
    <>
      <div className="loaderSection">
        <div className="loaderSectionChild">
        <span className="loaderCsv"></span>
        </div>
      </div>
      
    </>
  );
};

export default index;
